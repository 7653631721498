<template>
  <section class="loading-container">
  </section>
</template>

<script>

export default {
  name: 'Fac3dWaitingRoom',
  data() {
    return {
      loading: false,
    };
  },
};
</script>
